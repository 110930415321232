import { ResourceService } from "../common/common.services";
import _ from "lodash";
import { saveAs } from "file-saver";
import promisePoller from "promise-poller";
import mixpanel from "mixpanel-browser";
import { ENGLISH } from "../../app.constants";
import { publicIpv4 } from "public-ip";
import { downloadFile } from "../common/common.utils";
import { Draft04, JsonError } from "json-schema-library";
import {
    AUTHENTICATED_PERMISSION,
    PERMISSION_NAMES,
} from "../auth/auth.constants";
import { Client as ConversationsClient } from "@twilio/conversations";
import { setUser, captureMessage, captureException } from "@sentry/browser";
import { searchClient } from "@algolia/client-search";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import instantsearch from "instantsearch.js";

import { UNDERSCORE_DATE_FORMAT } from "../paperwork/constants";
import * as Sqrl from "squirrelly";
import moment from "moment";
import { CLOSED } from "../communication/constants";
import { CANCELLED, COMPLETE } from "../safety/safety.constants";
import { CHARGE_AUTOMATICALLY } from "../common/common.constants";

Sqrl.filters.define("firstName", function (s) {
    return s.split(" ")[0];
});

Sqrl.filters.define("lastName", function (s) {
    return s.split(" ").slice(1).join(" ");
});

export class GroupService extends ResourceService {
    /* @ngInject */
    constructor(
        Restangular,
        $q,
        MembershipService,
        GroupManagementMembershipService,
    ) {
        super(Restangular, $q, "groups");
        this.MembershipService = MembershipService;
        this.GroupManagementMembershipService =
            GroupManagementMembershipService;
        let ctrl = this;

        this.Restangular.extendModel(this.resource, function (model) {
            model.addMember = function (userSid) {
                return ctrl.MembershipService.create({
                    group: this.sid,
                    user: userSid,
                }).then(
                    function (membership) {
                        return membership;
                    },
                    function (reason) {
                        return reason.data;
                    },
                );
            };

            model.addManager = function (user, permissions) {
                return ctrl.GroupManagementMembershipService.create({
                    user: user,
                    group: this.sid,
                    active: true,
                    permissions: permissions,
                });
            };

            return model;
        });
    }
}

export class MembershipService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "memberships");

        this.Restangular.extendModel(this.resource, function (model) {
            model.end = function () {
                this.active = false;
                this.end_date = moment();
                return this.save();
            };

            return model;
        });
    }
}

export class GroupManagementMembershipService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "groupmanagementmemberships");
    }
}

export class GroupManagementPermissionService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "groupmanagementpermissions");
    }
}

export class SyncRecordService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "syncrecords");
    }
}

export class FarmService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, ManagementMembershipService) {
        super(Restangular, $q, "farms");

        let ctrl = this;
        this.ManagementMembershipService = ManagementMembershipService;

        this.Restangular.extendModel(this.resource, function (model) {
            model.addManager = function (user, permissions) {
                return ctrl.ManagementMembershipService.create({
                    user: user,
                    farm: this.sid,
                    active: true,
                    permissions: permissions,
                });
            };

            return model;
        });
    }
}

export class CropService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "crops");
    }
}

export class ManagementPermissionService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "managementpermissions");
    }
}

export class ManagementMembershipService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "managementmemberships");
    }
}

export class GrowerManagementMembershipService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "growermanagementmemberships");
    }
}

export class GrowerManagementPermissionService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "growermanagementpermissions");
    }
}

export class GrowerMembershipService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $http) {
        super(Restangular, $q, "growermemberships");
        this.$http = $http;

        this.Restangular.extendModel(this.resource, function (model) {
            model.end = function () {
                this.active = false;
                this.end_date = moment();
                return this.save();
            };

            model.sendSelfieLink = function () {
                return this.customGET("sendselfielink");
            };

            model.sendHandbook = function () {
                return this.customGET("sendhandbook");
            };

            model.sendOwnDocumentsLink = function () {
                return this.customGET("sendowndocumentslink");
            };

            model.clearPhoto = function () {
                return this.customPOST(undefined, "clear_photo");
            };

            return model;
        });
    }

    new(params) {
        return this.Restangular.all(this.resource).customPOST(params, "new");
    }

    getAlgoliaKey() {
        return this.Restangular.all(this.resource).customGET("algolia");
    }

    search(query, filters) {
        let svc = this;
        return svc.getSearchClient().then(function (searchClient) {
            return svc.$q.when(
                searchClient.searchSingleIndex({
                    indexName: "grower_membership_index",
                    searchParams: {
                        filters,
                        query,
                    },
                }),
            );
        });
    }

    getSearchClient() {
        let svc = this;
        if (svc.searchClient) {
            let deferred = svc.$q.defer();
            deferred.resolve(svc.searchClient);
            return deferred.promise;
        } else {
            return svc.getAlgoliaKey().then(function (algoliaParams) {
                svc.searchClient = searchClient(
                    process.env.ALGOLIA_APP_ID,
                    algoliaParams.public_key,
                );
                return svc.searchClient;
            });
        }
    }

    sendOwnDocumentsLink(sid) {
        return this.Restangular.one(this.resource, sid).customGET(
            "sendowndocumentslink",
        );
    }

    sync(syncProperties) {
        let svc = this;
        let fd = new FormData();
        fd.append("file", syncProperties.file);
        fd.append("grower", syncProperties.grower);
        return svc.$http.post(
            _.join([process.env.API_DOMAIN, "api", svc.resource, "sync"], "/"),
            fd,
            {
                headers: { "Content-Type": undefined },
                transformRequest: angular.identity,
            },
        );
    }
}

export class ExportFormatService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "exportformats");
    }
}
export class NoticeSubscriptionService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "noticesubscriptions");

        let ctrl = this;
        ctrl.Restangular.extendModel(this.resource, function (model) {
            model.unsubscribe = function () {
                this.active = false;
                return this.save();
            };

            return model;
        });
    }
}

export class ThemeService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "themes");
    }
}

export class TradeOrganizationService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "tradeorganizations");
    }
}

export class ProfileService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "profiles");
    }
}

export class AccountService extends ResourceService {
    /* @ngInject */
    constructor(
        Restangular,
        $q,
        PermRoleStore,
        PermPermissionStore,
        $translate,
        amMoment,
        AuthService,
    ) {
        super(Restangular, $q, "users");
        let ctrl = this;
        ctrl.PermPermissionStore = PermPermissionStore;
        ctrl.AuthService = AuthService;

        ctrl.Restangular.extendModel(this.resource, function (model) {
            model.updateMixpanel = function () {
                mixpanel.identify(this.sid);
                let mixpanelPeopleProperties = {
                    $email: this.email,
                    $phone: this.mobile,
                    preferred_language: this.preferred_language,
                };
                if (this.profile) {
                    mixpanelPeopleProperties.$name =
                        this.profile.first_name + " " + this.profile.last_name;
                    mixpanelPeopleProperties.gender = this.profile.gender;

                    if (this.profile.birth_date) {
                        mixpanelPeopleProperties.age = moment
                            .duration(
                                moment().diff(moment(this.profile.birth_date)),
                            )
                            .asYears();
                    }
                }
                mixpanel.people.set(mixpanelPeopleProperties);
                mixpanel.register({
                    experiment_branch: process.env.BRANCH,
                });
            };

            model.updateSentry = function () {
                setUser({
                    id: this.sid,
                    phone: this.mobile,
                });
            };

            model.updateIterate = function () {
                Iterate("identify", {
                    email: this.email,
                    external_id: this.sid,
                });
            };

            model.updateLanguage = function () {
                let language = this.preferred_language;
                if (_.isNil(language)) {
                    language = ENGLISH;
                }
                $translate.use(language);
                amMoment.changeLocale(language + "-us");
            };

            model.generateLoginLink = function () {
                mixpanel.track("Generated login link from employee profile");
                return this.customGET("generateloginlink");
            };

            model.sendLoginLink = function () {
                mixpanel.track("Sent login link from employee profile");
                return this.customGET("sendloginlink");
            };

            return model;
        });
    }

    updatePermissions(growerManagements, growerMemberships) {
        let ctrl = this;
        ctrl.PermPermissionStore.definePermission(
            AUTHENTICATED_PERMISSION,
            function () {
                return ctrl.AuthService.getAuthenticatedUserSid();
            },
        );
        ctrl.PermPermissionStore.defineManyPermissions(
            PERMISSION_NAMES,
            function (permissionName, transitionProperties) {
                let toStateName = transitionProperties.toState.name;
                let toParams = transitionProperties.toParams;
                let growerManagement = _.find(growerManagements, [
                    "grower_organization",
                    toParams.grower_sid,
                ]);
                let growerManagementPermissions = _.get(
                    growerManagement,
                    "permissions",
                    [],
                );
                return _.includes(
                    _.map(growerManagementPermissions, "value"),
                    permissionName,
                );
            },
        );
    }

    invite(inviteUser) {
        return this.Restangular.all(this.resource).customPOST(
            inviteUser,
            "invite",
        );
    }

    signUp(params) {
        return this.Restangular.all(this.resource).customPOST(params, "signup");
    }
}

export class GrowerOrganizationService extends ResourceService {
    /* @ngInject */
    constructor(
        Restangular,
        $q,
        GrowerMembershipService,
        AccountService,
        GrowerManagementMembershipService,
    ) {
        super(Restangular, $q, "growerorganizations");
        this.GrowerMembershipService = GrowerMembershipService;
        this.AccountService = AccountService;
        this.GrowerManagementMembershipService =
            GrowerManagementMembershipService;

        let svc = this;
        this.Restangular.extendModel(this.resource, function (model) {
            model.addManager = function (user) {
                return svc.GrowerManagementMembershipService.create({
                    user: user,
                    grower_organization: this.sid,
                    active: true,
                });
            };

            model.switchToAutoPay = function () {
                this.stripe_collection_method = CHARGE_AUTOMATICALLY;
                return this.save();
            };

            return model;
        });
    }
}

export class MessageService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "messages");
    }
}
export class AttachmentService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "attachments");
    }
}
export class AnnouncementTRANSLATEDService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "announcementtranslateds");
    }
}
export class AnnouncementService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $http, DownloadService, $translate) {
        super(Restangular, $q, "announcements");
        this.$http = $http;
        this.DownloadService = DownloadService;
        this.$translate = $translate;

        let svc = this;
        svc.Restangular.extendModel(this.resource, function (model) {
            model.send = function () {
                return this.customPOST(undefined, "send");
            };

            model.print = function () {
                return this.customGET("print");
            };

            model.download = function () {
                return this.customGET("download");
            };

            model.delete = function () {
                this.active = false;
                return this.save();
            };

            return model;
        });
    }
}
export class ConversationService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $state) {
        super(Restangular, $q, "conversations");

        let svc = this;
        svc.$state = $state;
        svc.Restangular.extendModel(this.resource, function (model) {
            model.download = function () {
                return this.customGET("download");
            };
            model.close = function () {
                this.state = CLOSED;
                return this.save();
            };

            model.addParticipant = function () {
                return this.customPUT(undefined, "add_participant");
            };

            return model;
        });
    }

    getToken() {
        let svc = this;
        return svc.Restangular.all(this.resource).customGET("token");
    }

    createTwilioClient() {
        let svc = this;
        return svc.getToken().then(function (data) {
            let deferred = svc.$q.defer();
            const client = new ConversationsClient(data.token);
            client.on("tokenAboutToExpire", function () {
                svc.getToken().then(function (data) {
                    client.updateToken(data.token);
                });
            });
            client.on("tokenExpired", function () {
                svc.getToken().then(function (data) {
                    client.updateToken(data.token);
                });
            });
            // client.on("connectionError", function (error) {
            //     svc.$state.go("^");
            // });
            // client.on("connectionStateChanged", function (state) {
            //     if (state === "denied" || state === "disconnected") {
            //         svc.$state.go("^");
            //     }
            // });
            client.on("initialized", function () {
                deferred.resolve(client);
            });
            client.on("initFailed", function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        });
    }
}
export class AlertThemeService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "alertthemes");
    }
}
export class AlertSubscriptionService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "alertsubscriptions");
        let svc = this;
        svc.Restangular.extendModel(this.resource, function (model) {
            model.unsubscribe = function () {
                this.active = false;
                return this.save();
            };

            return model;
        });
    }
}
export class AccidentReportService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "accidentreports");

        this.Restangular.extendModel(this.resource, function (model) {
            model.prefill = function () {
                return this.customGET("prefill").then(
                    (response) => response.data,
                );
            };

            model.download = function () {
                return this.customGET("download");
            };

            model.archive = function () {
                this.archived = true;
                return this.save();
            };

            model.getReportFileName = function () {
                return (
                    "Accident Report OSHA 301 " +
                    moment(this.accident_date).format(UNDERSCORE_DATE_FORMAT)
                );
            };

            return model;
        });
    }

    downloadLog(farm, year) {
        let ctrl = this;
        return ctrl.Restangular.all(ctrl.resource).customPOST(
            { farm: farm, year: year },
            "download_log",
        );
    }

    downloadLogAndSummary(data) {
        let ctrl = this;
        return ctrl.Restangular.all(ctrl.resource).customPOST(
            data,
            "download_log_summary",
        );
    }
}

export class AccidentReportTranslatedService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "accidentreporttranslateds");
    }
}
export class CommentService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "safetycomments");
    }
}

export class CommentTRANSLATEDService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "safetycommentstranslateds");
    }
}

export class TrainingRequirementService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "trainingrequirements");
    }
}

export class SignatureService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "signatures");
    }
}

export class CredentialService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "credentials");
    }
}

export class CredentialTypeService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "credentialtypes");
    }
}

export class SafetyCourseService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "safetycourses");
    }
}

export class SafetyMeetingRequestService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, SignatureService, DownloadService, $http) {
        super(Restangular, $q, "safetymeetingrequests");

        this.DownloadService = DownloadService;
        this.$http = $http;

        this.rejectionResponses = {
            403: "SafetyMeetingRequestService.LOCKED_TOAST",
            404: "SafetyMeetingRequestService.NOT_FOUND_TOAST",
        };

        this.Restangular.extendModel(this.resource, function (model) {
            model.complete = function (
                certifier,
                certificationType,
                signatureImageData = null,
            ) {
                let instance = this;
                instance.status = COMPLETE;
                instance.certifier = certifier.sid;
                instance.certification_type = certificationType;
                let signatureResponses = [];
                if (signatureImageData) {
                    let response = SignatureService.create({
                        signer: instance.subject,
                        image_data: signatureImageData,
                        grower_organization: instance.requester,
                    });
                    signatureResponses.push(response);
                }
                return $q.all(signatureResponses).then(function (responses) {
                    let signature = _.first(responses);
                    if (signature) {
                        instance.signature = signature.sid;
                    }
                    instance.completion_date = moment();
                    return instance.save();
                });
            };

            model.starting = function () {
                this.start_date = moment();
                return this.save();
            };

            model.updateWatched = function (payload) {
                this.watched = payload;
                return this.save();
            };

            model.notify = function () {
                return this.customGET("notify");
            };

            model.cancel = function () {
                this.status = CANCELLED;
                this.cancelled_date = moment();
                return this.save();
            };

            return model;
        });
    }

    handleRejection(reason) {
        let svc = this;
        return svc.rejectionResponses[reason.status];
    }

    downloadHistory(trainee, requester) {
        let ctrl = this;
        return ctrl.Restangular.all(ctrl.resource).customPOST(
            { user: trainee.sid, requester: requester.sid },
            "download_training_history",
        );
    }

    downloadHistoryData(trainees, requester, from_date, to_date) {
        let ctrl = this;
        return ctrl.Restangular.all(ctrl.resource).customPOST(
            {
                subjects: trainees,
                requester: requester.sid,
                from_date,
                to_date,
            },
            "download_training_history_data",
        );
    }

    downloadTrainingTime(options) {
        let ctrl = this;
        return ctrl.Restangular.all(ctrl.resource)
            .customPOST(options, "download_training_time")
            .then(function (response) {
                let downloadUrl = response.download_url;
                promisePoller({
                    retries: 10,
                    taskFn: function () {
                        return ctrl.$http.get(downloadUrl);
                    },
                }).then(function () {
                    let filename = downloadUrl
                        .split("/")
                        .pop()
                        .split("#")[0]
                        .split("?")[0];
                    saveAs(downloadUrl, decodeURIComponent(filename));
                });
            });
    }
}

export class SafetyMeetingTopicTRANSLATEDService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "safetymeetingtopictranslateds");
    }
}

export class SafetyMeetingTopicService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $http) {
        super(Restangular, $q, "safetymeetingtopics");
        this.$http = $http;

        this.Restangular.extendModel(this.resource, function (model) {
            model.getDetail = function () {
                return this.customGET("content");
            };
            return model;
        });
    }

    getAlgoliaKey() {
        return this.Restangular.all(this.resource).customGET("algolia");
    }
}

export class PublicTrainingService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $http) {
        super(Restangular, $q, "public-trainings");
        this.$http = $http;
    }

    getAlgoliaKey() {
        let svc = this;
        return svc.$http
            .get(
                _.join(
                    [process.env.API_DOMAIN, "api", svc.resource, "algolia"],
                    "/",
                ),
                {
                    skipAuthorization: true,
                },
            )
            .then((response) => response.data);
    }

    listPublic() {
        let svc = this;
        return svc.$http
            .get(_.join([process.env.API_DOMAIN, "api", svc.resource], "/"), {
                skipAuthorization: true,
            })
            .then((response) => response.data);
    }

    retrievePublic(sid) {
        let svc = this;
        return svc.$http
            .get(
                _.join([process.env.API_DOMAIN, "api", svc.resource, sid], "/"),
                { skipAuthorization: true },
            )
            .then((response) => response.data);
    }
}

export class SafetyMeetingService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, SignatureService) {
        super(Restangular, $q, "safetymeetings");

        this.Restangular.extendModel(this.resource, function (model) {
            model.getReport = function (includeComments) {
                return this.customGET("report", {
                    include_comments: includeComments,
                });
            };

            model.getReportFileName = function () {
                return (
                    "Safety meeting - training record " +
                    this.name +
                    " " +
                    moment(this.start_date).format(UNDERSCORE_DATE_FORMAT)
                );
            };

            model.getFieldReport = function () {
                return this.customGET("field_report");
            };

            model.getFieldReportFileName = function () {
                return (
                    "Field report " +
                    this.name +
                    " " +
                    moment(this.start_date).format(UNDERSCORE_DATE_FORMAT) +
                    ".pdf"
                );
            };

            model.finish = function (
                signatureImageData,
                finishUser,
                finishGrower,
            ) {
                let instance = this;
                let signatureResponses = [];
                instance.scheduler = finishUser.sid;
                if (signatureImageData) {
                    let response = SignatureService.create({
                        signer: instance.scheduler,
                        image_data: signatureImageData,
                        grower_organization: finishGrower.sid,
                    });
                    signatureResponses.push(response);
                }
                return $q.all(signatureResponses).then(function (responses) {
                    let signature = _.first(responses);
                    if (signature) {
                        instance.finish_signature = signature.sid;
                    }
                    instance.finish_date = moment();
                    return instance.customPUT(undefined, "finish");
                });
            };

            model.cancel = function () {
                let instance = this;
                instance.active = false;
                return instance.save();
            };

            model.sendCrewBossAttendanceLink = function (recipients) {
                return this.customPOST(
                    { recipients },
                    "send_crew_boss_attendance",
                );
            };

            model.sendOrientationReminder = function (userSid) {
                return this.customPOST(
                    {
                        user: userSid,
                    },
                    "send_orientation_reminder",
                );
            };

            model.sendRegularReminderToIncomplete = function (
                firstTime = false,
            ) {
                return this.customPOST(
                    {
                        first_time: firstTime,
                    },
                    "send_regular_reminder",
                );
            };

            model.sendShortReminderToIncomplete = function (firstTime = false) {
                return this.customPOST(
                    {
                        first_time: firstTime,
                    },
                    "send_short_reminder",
                );
            };

            model.messageIncompleteParticipants = function (data) {
                return this.customPOST(data, "send_message");
            };

            return model;
        });
    }
}

export class DocumentPackageService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $http) {
        super(Restangular, $q, "documentpackages");

        this.Restangular.extendModel(this.resource, function (model) {
            model.requiresCustomization = function () {
                let schema = this.translations[0].schema;
                return (
                    _.intersection(
                        schema.required,
                        _.get(this.subject_fields, "requester", []),
                    ).length > 0
                );
            };

            model.customizationIsValid = function (customizationTranslateds) {
                let instance = this;
                return _.every(customizationTranslateds, function (translated) {
                    let x = translated.isValid(instance);
                    console.log("is valid: " + x);
                    return x;
                });
            };

            model.displayName = function (customization) {
                let str = this.name;
                if (customization) {
                    str += " (" + customization.name + ")";
                }
                return str;
            };

            model.formatDates = function (data) {
                let instance = this;
                _.forEach(instance.date_fields, function (options, field) {
                    let dateValue = _.get(data, field);
                    if (dateValue) {
                        data[field] = moment(dateValue).format(options.format);
                    }
                });
                return data;
            };

            model.makeDates = function (data) {
                let instance = this;
                _.forEach(instance.date_fields, function (options, field) {
                    let dateValue = _.get(data, field);
                    if (dateValue) {
                        data[field] = moment(dateValue, options.format);
                    }
                });
                return data;
            };

            model.makeDefaultDates = function (data, role) {
                let instance = this;
                let fieldsToCheck = _.get(
                    instance.fields,
                    role,
                    _.flatten(_.values(instance.fields)),
                );
                _.forEach(instance.date_fields, function (options, field) {
                    if (
                        _.includes(fieldsToCheck, field) &&
                        options.default === "now" &&
                        _.isEmpty(data[field])
                    ) {
                        data[field] = moment().format(options.format);
                    }
                });
                return data;
            };

            model.getFormSchemaRaw = function () {
                let instance = this;
                return instance.customGET("schema");
            };

            model.getPageDimensions = function (pageIndex) {
                let instance = this;
                const template_specification = instance.template_specification;
                if (template_specification.template_type === "html") {
                    pageIndex = -1;
                }
                return instance.template_specification.page_dimensions[
                    pageIndex
                ];
            };

            model.getSignaturePages = function (
                role,
                neededSignatureFields,
                preferredLanguage,
            ) {
                let instance = this;
                const neededFieldsSet = new Set(
                    _.intersection(
                        instance.fields[role],
                        neededSignatureFields,
                    ),
                );
                let pageIndexSet = new Set();
                const template_specification = instance.template_specification;
                for (const [fieldId, fieldInfo] of Object.entries(
                    template_specification.fields,
                )) {
                    if (neededFieldsSet.has(fieldInfo.name)) {
                        template_specification.field_order.forEach(
                            (fieldIds, pageIndex) => {
                                if (fieldIds.includes(parseInt(fieldId))) {
                                    pageIndexSet.add(pageIndex);
                                }
                            },
                        );
                    }
                }
                return Array.from(pageIndexSet);
            };

            return model;
        });
    }
}

export class DocumentPackageTranslatedService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "documentpackagetranslateds");

        this.Restangular.extendModel(this.resource, function (model) {
            model.createTemplate = function (externalFileId) {
                let data = {
                    external_file_id: externalFileId,
                };
                return this.customPOST(data, "create_template");
            };

            return model;
        });
    }

    renderPreview(sid) {
        let svc = this;
        return svc.Restangular.one(svc.resource, sid).customPOST(
            {},
            "render_preview",
        );
    }
}

export class DocumentPackageRequestService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "documentpackagerequests");

        this.Restangular.extendModel(this.resource, function (model) {
            model.cancel = function () {
                this.cancelled = true;
                this.cancelled_date = moment();
                return this.save();
            };

            model.sign = function (formData, signerSid, initiatorSid) {
                let data = {
                    form_data: formData,
                    signer: signerSid,
                    initiator: initiatorSid,
                };
                return this.customPOST(data, "sign");
            };

            model.edit = function (formData, initiatorSid) {
                let data = {
                    form_data: formData,
                    initiator: initiatorSid,
                };
                return this.customPOST(data, "edit");
            };

            model.notify = function () {
                return this.customGET("notify");
            };

            model.promptExternal = function () {
                return this.customGET("prompt_external");
            };

            model.renderPreview = function (data) {
                return this.customPOST({ data }, "render_preview");
            };

            model.updateData = function (data) {
                return this.customPOST({ data }, "update_data");
            };

            model.isInPrefill = function () {
                return (
                    this.initiator_fill_first && this.latest_signer_order === -1
                );
            };

            return model;
        });
    }

    prefillRequest(requestSid, currentInitiatorSid) {
        let svc = this;
        let payload;
        if (currentInitiatorSid) {
            payload = { current_initiator: currentInitiatorSid };
        } else {
            payload = {};
        }
        return svc.Restangular.one(svc.resource, requestSid)
            .customPOST(payload, "prefill")
            .then((response) => response.data);
    }

    createRequestsFromPacket(data) {
        let svc = this;
        return svc.Restangular.all(svc.resource).customPOST(
            data,
            "create_from_packet",
        );
    }

    notify(data) {
        let svc = this;
        return svc.Restangular.all(svc.resource).customPOST(
            data,
            "notify_generic",
        );
    }
}

export class DocumentPackageRecordService extends ResourceService {
    /* @ngInject */
    constructor(
        Restangular,
        $q,
        $window,
        ToastService,
        $http,
        PollDownloadService,
    ) {
        super(Restangular, $q, "documentpackagerecords");
        this.ToastService = ToastService;
        this.$http = $http;
        this.PollDownloadService = PollDownloadService;
        let svc = this;

        this.Restangular.extendModel(this.resource, function (model) {
            model.archive = function () {
                mixpanel.track("Archived document");
                this.archived = true;
                return this.save();
            };

            model.download = function () {
                this.customGET("download").then(function (response) {
                    return svc.PollDownloadService.get(response).then(
                        function (url) {
                            downloadFile(url);
                        },
                        function (reasons) {
                            svc.ToastService.create(
                                "DocumentPackageRecordService.POLL_FAILED",
                            );
                            captureException(
                                "DocumentPackageRecord data export failed",
                                {
                                    contexts: {
                                        error: {
                                            attempts: reasons.length,
                                            last_attempt: _.last(reasons),
                                        },
                                    },
                                },
                            );
                        },
                    );
                });
            };

            return model;
        });
    }

    downloadBulk(options) {
        let svc = this;
        return svc.Restangular.all(svc.resource).customPOST(
            options,
            "download_bulk",
        );
    }

    dataDownload(options) {
        let svc = this;
        return svc.Restangular.all(svc.resource)
            .customPOST(options, "data_download")
            .then(function (response) {
                return svc.PollDownloadService.get(response).then(
                    function (url) {
                        downloadFile(url);
                    },
                    function (reasons) {
                        svc.ToastService.create(
                            "DocumentPackageRecordService.POLL_FAILED",
                        );
                        captureException(
                            "DocumentPackageRecord data export failed",
                            {
                                contexts: {
                                    error: {
                                        attempts: reasons.length,
                                        last_attempt: _.last(reasons),
                                    },
                                },
                            },
                        );
                    },
                );
            });
    }
}

export class PacketService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "packets");
    }
}

export class PacketMemberService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "packetmembers");
    }
}

export class SignatureRequestService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, AuditTrailEventService) {
        super(Restangular, $q, "signaturerequests");

        this.Restangular.extendModel(this.resource, function (model) {
            model.updateDataRequest = function () {
                return this.customGET("updatedatarequest");
            };

            model.getAuthToken = function () {
                return this.customGET("authtoken");
            };

            model.refresh = function () {
                return this.customGET("refresh");
            };

            model.notify = function () {
                return this.customGET("notify");
            };

            model.addAuditTrailEvent = function (event, context = {}) {
                return AuditTrailEventService.create(
                    {
                        date: moment(),
                        event: event,
                        user: this.signer,
                        document_package_request: this.document_package_request,
                        signature_request: this.sid,
                        context: context,
                        grower_membership: this.grower_membership,
                    },
                    function (reason) {},
                );
            };

            model.addAuditTrailEventOLD = function (event) {
                let data = {
                    date: moment(),
                    event: event,
                };
                return this.customPOST(data, "add_audit_trail_event").catch(
                    (e) => {},
                );
            };

            return model;
        });
    }
}

export class AuditTrailEventService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $translate, $window) {
        super(Restangular, $q, "audittrailevents");
        this.$translate = $translate;
        this.$window = $window;
        this.currentIPAddress;
    }

    determineIPAddress() {
        let svc = this;
        return publicIpv4().then(
            function (ipAddress) {
                svc.currentIPAddress = ipAddress;
            },
            function (error) {
                svc.currentIPAddress = null;
            },
        );
    }

    create(data) {
        let svc = this;
        data.context.language = svc.$translate.use();
        data.ip_address = svc.currentIPAddress;
        data.user_agent = svc.$window.navigator.userAgent;
        return super.create(data);
    }
}

export class IdentificationDocumentService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "identificationdocuments");

        this.Restangular.extendModel(this.resource, function (model) {
            model.archive = function () {
                this.archived = true;
                return this.save();
            };

            return model;
        });
    }
}

export class IdentificationDocumentTypeService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "identificationdocumenttypes");

        this.Restangular.extendModel(this.resource, function (model) {
            model.clean = function (
                data,
                documentPackage,
                convertDatesToMoment = true,
            ) {
                let fieldMappings = this.field_mappings.harvust;
                let defaultFieldMappings = _.get(fieldMappings, "default", []);
                let fieldMappingForDocumentPackage = _.get(
                    fieldMappings,
                    documentPackage.sid,
                    defaultFieldMappings,
                );
                let allDocumentPackageFields = _.flatten(
                    _.values(documentPackage.fields),
                );
                let cleanData = {};
                _.forEach(
                    fieldMappingForDocumentPackage,
                    function (options, field) {
                        if (_.includes(allDocumentPackageFields, field)) {
                            let template = options.template;
                            let value;

                            if (_.isUndefined(template)) {
                                value = options.default;
                            } else {
                                try {
                                    value = Sqrl.render(template, data);
                                } catch (e) {
                                    value = null;
                                }
                            }

                            if (
                                typeof value === "string" &&
                                !(
                                    value.includes("null") ||
                                    value.includes("undefined")
                                )
                            ) {
                                if (
                                    options.data_type === "date" &&
                                    _.has(documentPackage.date_fields, field)
                                ) {
                                    if (convertDatesToMoment) {
                                        value = moment(
                                            value,
                                            documentPackage.date_fields[field]
                                                .format,
                                        );
                                    }
                                }
                                cleanData[field] = value;
                            }
                        }
                    },
                );
                return cleanData;
            };

            return model;
        });
    }
}

export class OnboardingConfigurationService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "onboardingconfigurations");
    }
}

export class OnboardingConfigurationTRANSLATEDService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "onboardingconfigurationtranslateds");
    }
}

export class SafetyMeetingProgramService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "safetymeetingprograms");
    }
}

export class SafetyMeetingProgramElementService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "safetymeetingprogramelements");
    }
}

export class SafetyMeetingProgramSubscriptionService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "safetymeetingprogramsubscriptions");

        let ctrl = this;
        ctrl.Restangular.extendModel(this.resource, function (model) {
            model.unsubscribe = function () {
                this.active = false;
                return this.save();
            };

            return model;
        });
    }
}

export class TimeTrackingVendorService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "timetrackingvendors");
        let svc = this;
        svc.Restangular.extendModel(this.resource, function (model) {
            model.link = function (grower, link_code) {
                return this.customPOST(
                    {
                        grower_organization: grower.sid,
                        link_code,
                        time_tracking_vendor: this.sid,
                    },
                    "link",
                );
            };

            return model;
        });
    }
}

export class TimeTrackingVendorConfigurationService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "timetrackingvendorconfigurations");

        let FIELDCLOCK_COLOR = "bg-[#23c6c8]";
        let T3_COLOR = "bg-[#17405e]";
        let ROY_FARMS_COLOR = "bg-[#90C400]";
        let DOUGLAS_FRUIT_COLOR = "bg-[#f15a29]";
        let PAGO_COLOR = "bg-[#258758]";
        let AGSQUARED_COLOR = "bg-[#4d7e11]";
        let AGRITRAK_COLOR = "bg-[#576240]";

        let ctrl = this;
        ctrl.Restangular.extendModel(this.resource, function (model) {
            model.search = function (queryParams) {
                return this.customGET("search", queryParams);
            };

            model.create = function (growerMembershipSid) {
                return this.customPOST(
                    { grower_membership: growerMembershipSid },
                    "create_time_tracking_employee",
                );
            };

            model.getBadge = function (growerMembershipSid) {
                return this.customGET("badge", {
                    grower_membership: growerMembershipSid,
                });
            };

            return model;
        });
    }

    find_grower_membership(value, growerSid) {
        let svc = this;
        return svc.Restangular.all(svc.resource).customGET(
            "find_grower_membership",
            { value: value, grower_organization: growerSid },
        );
    }
}

export class TimeTrackingEmployeeConfigurationService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "timetrackingemployeeconfigurations");
    }
}

export class ConversationNoteService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "conversationnotes");
    }
}

export class ConversationNoteTranslatedService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "conversationnotetranslateds");
    }
}

export class AdvisorConversationService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "advisorconversations");
    }

    getToken() {
        let svc = this;
        return svc.Restangular.all(this.resource).customGET("token");
    }

    createTwilioClient() {
        let svc = this;
        return svc.getToken().then(function (data) {
            let deferred = svc.$q.defer();
            const client = new ConversationsClient(data.token);
            client.on("tokenAboutToExpire", function () {
                svc.getToken().then(function (data) {
                    client.updateToken(data.token);
                });
            });
            client.on("tokenExpired", function () {
                svc.getToken().then(function (data) {
                    client.updateToken(data.token);
                });
            });
            // client.on("connectionError", function (error) {
            //     svc.$state.go("^");
            // });
            // client.on("connectionStateChanged", function (state) {
            //     if (state === "denied" || state === "disconnected") {
            //         svc.$state.go("^");
            //     }
            // });
            client.on("initialized", function () {
                deferred.resolve(client);
            });
            client.on("initFailed", function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        });
    }
}

export class AdvisorSnippetService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q, $http) {
        super(Restangular, $q, "advisorsnippets");
        this.$http = $http;

        let ctrl = this;
        ctrl.Restangular.extendModel(this.resource, function (model) {
            model.share = function (shouldIndex) {
                this.public = true;
                if (shouldIndex) {
                    this.indexable = shouldIndex;
                }
                return this.save();
            };

            return model;
        });
    }

    getPublic(sid) {
        let svc = this;
        return svc.$http
            .get(
                _.join(
                    [
                        process.env.API_DOMAIN,
                        "api",
                        svc.resource,
                        sid,
                        "public",
                    ],
                    "/",
                ),
                { skipAuthorization: true },
            )
            .then((response) => response.data);
    }
}

export class AdvisorMessageService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "advisormessages");
    }
}

export class H2ACandidateService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "h2acandidates");
    }
}

export class HousingSiteService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "housingsites");
    }
}

export class HousingAssignmentService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "housingassignments");

        this.Restangular.extendModel(this.resource, function (model) {
            model.end = function () {
                this.active = false;
                this.move_out_date = moment();
                return this.save();
            };

            return model;
        });
    }
}

export class DocumentPackageCustomizationService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "documentpackagecustomizations");
    }
}

export class DocumentPackageCustomizationTranslatedService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "documentpackagecustomizationtranslateds");

        this.Restangular.extendModel(this.resource, function (model) {
            model.renderPreview = function (data) {
                return this.customPOST({ data }, "render_preview");
            };

            model.isValid = function (documentPackage) {
                let schema = documentPackage.translations[0].schema;
                let requesterFields = _.get(
                    documentPackage,
                    "subject_fields.requester",
                    [],
                );
                let propertiesToValidate = _.pickBy(
                    schema.properties,
                    (value, key) => {
                        return (
                            requesterFields.includes(key) ||
                            key.startsWith("harvust__")
                        );
                    },
                );
                console.log("validting customization");
                console.log(schema.properties);
                console.log(propertiesToValidate);
                schema.properties = propertiesToValidate;
                schema.required = _.filter(
                    schema.required,
                    function (fieldName) {
                        return _.includes(_.keys(schema.properties), fieldName);
                    },
                );
                console.log(schema);
                const jsonSchema = new Draft04(schema);
                return jsonSchema.isValid(this.data);
            };

            return model;
        });
    }
}

export class DisciplineReportService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "disciplinereports");

        this.Restangular.extendModel(this.resource, function (model) {
            model.download = function (language) {
                return this.customGET("download", { language });
            };

            return model;
        });
    }
}

export class DisciplineReportTranslatedService extends ResourceService {
    /* @ngInject */
    constructor(Restangular, $q) {
        super(Restangular, $q, "disciplinereporttranslateds");
    }
}
