"use strict";

import _ from "lodash";
import angular from "angular";
import SlimSelect from "slim-select";
import supportsTime from "time-input-polyfill/supportsTime";
import TimePolyfill from "time-input-polyfill";
import moment from "moment";
import videojs from "video.js";
import phone from "phone";

import phoneNumberInputTemplate from "./phone_number_input.html";
import cfVideoTemplate from "./cf_video.html";
import otpInputTemplate from "./otp_input.html";
import textInputTemplate from "./text_input.html";
import textAreaTemplate from "./textarea_input.html";
import radioInputTemplate from "./radio_input.html";
import cardHeaderTemplate from "./card_header.html";
import actionCardTemplate from "./action_card.html";
import passwordInputTemplate from "./password_input.html";
import formTripleActionTemplate from "./form_triple_action.html";
import spinnerTemplate from "./spinner.html";
import profilePictureTemplate from "./profile_picture.html";
import { marked } from "marked";
import uiLinkTemplate from "./ui_link.html";
import anchorButtonTemplate from "./anchor_button.html";
import warning from "@material-design-icons/svg/outlined/warning.svg";
import arrow_forward from "@material-design-icons/svg/outlined/arrow_forward.svg";
import update from "@material-design-icons/svg/outlined/update.svg";
import check_circle from "@material-design-icons/svg/outlined/check_circle.svg";
import timelapse from "@material-design-icons/svg/outlined/timelapse.svg";
import thumb_up from "@material-design-icons/svg/outlined/thumb_up.svg";
import close from "@material-design-icons/svg/outlined/close.svg";
import done from "@material-design-icons/svg/outlined/done.svg";
import home from "@material-design-icons/svg/outlined/home.svg";
import help from "@material-design-icons/svg/outlined/help.svg";
import settings from "@material-design-icons/svg/outlined/settings.svg";
import menu from "@material-design-icons/svg/outlined/menu.svg";
import arrow_drop_down from "@material-design-icons/svg/outlined/arrow_drop_down.svg";
import add from "@material-design-icons/svg/outlined/add.svg";
import file_download from "@material-design-icons/svg/outlined/file_download.svg";
import fiber_manual_record from "@material-design-icons/svg/outlined/fiber_manual_record.svg";
import place from "@material-design-icons/svg/outlined/place.svg";
import highlight_off from "@material-design-icons/svg/outlined/highlight_off.svg";
import print from "@material-design-icons/svg/outlined/print.svg";
import autorenew from "@material-design-icons/svg/outlined/autorenew.svg";
import clear_all from "@material-design-icons/svg/outlined/clear_all.svg";
import attach_file from "@material-design-icons/svg/outlined/attach_file.svg";
import add_location from "@material-design-icons/svg/outlined/add_location.svg";
import play_circle_outline from "@material-design-icons/svg/outlined/play_circle_outline.svg";
import description from "@material-design-icons/svg/outlined/description.svg";
import person from "@material-design-icons/svg/outlined/person.svg";
import volume_up from "@material-design-icons/svg/outlined/volume_up.svg";
import arrow_back from "@material-design-icons/svg/outlined/arrow_back.svg";
import schedule from "@material-design-icons/svg/outlined/schedule.svg";
import delete_icon from "@material-design-icons/svg/outlined/delete.svg";
import import_export from "@material-design-icons/svg/outlined/import_export.svg";
import expand_more from "@material-design-icons/svg/outlined/expand_more.svg";
import expand_less from "@material-design-icons/svg/outlined/expand_less.svg";
import remove_circle_outline from "@material-design-icons/svg/outlined/remove_circle_outline.svg";
import class_icon from "@material-design-icons/svg/outlined/class.svg";
import send from "@material-design-icons/svg/outlined/send.svg";
import phone_enabled from "@material-design-icons/svg/outlined/phone_enabled.svg";
import send_to_mobile from "@material-design-icons/svg/outlined/send_to_mobile.svg";
import phone_disabled from "@material-design-icons/svg/outlined/phone_disabled.svg";
import arrow_right_alt from "@material-design-icons/svg/outlined/arrow_right_alt.svg";
import link from "@material-design-icons/svg/outlined/link.svg";
import open_in_browser from "@material-design-icons/svg/outlined/open_in_browser.svg";
import open_in_new from "@material-design-icons/svg/outlined/open_in_new.svg";
import check from "@material-design-icons/svg/outlined/check.svg";
import cancel from "@material-design-icons/svg/outlined/cancel.svg";
import sort from "@material-design-icons/svg/outlined/sort.svg";
import filter_list from "@material-design-icons/svg/outlined/filter_list.svg";
import cancel_presentation from "@material-design-icons/svg/outlined/cancel_presentation.svg";
import assignment from "@material-design-icons/svg/outlined/assignment.svg";
import notifications_active from "@material-design-icons/svg/outlined/notifications_active.svg";
import person_add from "@material-design-icons/svg/outlined/person_add.svg";
import create from "@material-design-icons/svg/outlined/create.svg";
import pan_tool from "@material-design-icons/svg/outlined/pan_tool.svg";
import done_all from "@material-design-icons/svg/outlined/done_all.svg";
import timer from "@material-design-icons/svg/outlined/timer.svg";
import call from "@material-design-icons/svg/outlined/call.svg";
import directions from "@material-design-icons/svg/outlined/directions.svg";
import email from "@material-design-icons/svg/outlined/email.svg";
import directions_car from "@material-design-icons/svg/outlined/directions_car.svg";
import health_and_safety from "@material-design-icons/svg/outlined/health_and_safety.svg";
import call_made from "@material-design-icons/svg/outlined/call_made.svg";
import navigate_next from "@material-design-icons/svg/outlined/navigate_next.svg";
import badge from "@material-design-icons/svg/outlined/badge.svg";
import upload from "@material-design-icons/svg/outlined/upload.svg";
import swap_horiz from "@material-design-icons/svg/outlined/swap_horiz.svg";
import bolt from "@material-design-icons/svg/outlined/bolt.svg";
import preview from "@material-design-icons/svg/outlined/preview.svg";
import library_books from "@material-design-icons/svg/outlined/library_books.svg";
import star from "@material-design-icons/svg/outlined/star.svg";
import currency_exchange from "@material-design-icons/svg/outlined/currency_exchange.svg";
import archive from "@material-design-icons/svg/outlined/archive.svg";
import chat from "@material-design-icons/svg/outlined/chat.svg";
import mic from "@material-design-icons/svg/outlined/mic.svg";
import stop from "@material-design-icons/svg/outlined/stop.svg";
import report from "@material-design-icons/svg/outlined/report.svg";
import { ENGLISH } from "../../app.constants";
import stateProgressBarTemplate from "./state_progress_bar.html";
import mixpanel from "mixpanel-browser";

/* @ngInject */
export function timePicker() {
    return {
        restrict: "A",
        require: "ngModel",
        link: function (scope, element, attrs, ngModelCtrl) {
            if (!supportsTime) {
                let $input = element[0];
                new TimePolyfill($input);
                if ($input.polyfill) {
                    //format text going to user (model to view)
                    ngModelCtrl.$formatters.push(function (value) {
                        return moment(value).format("hh:mm A");
                    });

                    //format text from the user (view to model)
                    ngModelCtrl.$parsers.push(function (value) {
                        return moment($input.dataset.value, "H:m A").toDate();
                    });
                }
            }
        },
    };
}

/* @ngInject */
export function slimSelect($timeout) {
    return {
        require: "ngModel",
        scope: {
            ngDisabled: "=ngDisabled",
            keepOrder: "=",
            ngModel: "=",
        },
        link: function (scope, element, attr, ngModelCtrl) {
            $timeout(function () {
                let rawElement = element[0];
                let opts = {
                    select: rawElement,
                    placeholder: false,
                    settings: {},
                    events: {
                        afterChange: (newVal) => {
                            updateModel(newVal);
                        },
                    },
                };
                opts.settings.keepOrder = _.get(scope, "keepOrder", false);
                let select = new SlimSelect(opts);

                let previousValue = [];

                function updateModel(newVal) {
                    if (opts.settings.keepOrder) {
                        let addedElements = _.differenceBy(
                            scope.ngModel,
                            previousValue,
                            "value",
                        );
                        let removedElements = _.differenceBy(
                            previousValue,
                            scope.ngModel,
                            "value",
                        );

                        let currentElements = _.pullAllBy(
                            previousValue,
                            removedElements,
                            "value",
                        );
                        currentElements = _.concat(
                            currentElements,
                            addedElements,
                        );

                        ngModelCtrl.$setViewValue(currentElements);

                        previousValue = currentElements;
                    }
                }

                scope.$watch("ngDisabled", function (value) {
                    if (scope.ngDisabled) {
                        select.disable();
                    } else {
                        select.enable();
                    }
                });
            });
        },
    };
}

/* @ngInject */
export function backButton() {
    return {
        restrict: "E",
        transclude: true,
        scope: {
            sref: "@sref",
        },
        template:
            '<a class="flex item-center" ui-sref="{{sref || \'^\'}}">' +
            '<material-icon name="arrow_back"></material-icon>' +
            "<span translate><ng-transclude></ng-transclude></span>" +
            "</a>",
    };
}

/* @ngInject */
export function simpleBackButton($window) {
    return {
        restrict: "E",
        transclude: true,
        template:
            '<span class="valign-wrapper cursor-pointer">' +
            '<material-icon name="arrow_back"></material-icon>' +
            "<span translate><ng-transclude></ng-transclude></span>" +
            "</span>",
        link: function (scope, element, attrs) {
            element.on("click", function () {
                $window.history.back();
            });
        },
    };
}

/* @ngInject */
export function resourceLink($window) {
    return {
        restrict: "A",
        scope: {
            url: "=",
        },
        link: function (scope, element, attrs) {
            element.on("click", function () {
                mixpanel.track("Resource link clicked", {
                    target_url: scope.url,
                });
                $window.open(scope.url, "_blank");
            });
        },
    };
}

/* @ngInject */
export function backImg() {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            let publicID = attrs.backImg;
            if (publicID) {
                element.css({
                    "background-image":
                        "url(https://res.cloudinary.com/harvust/image/upload/" +
                        publicID +
                        ".jpg)",
                });
            } else {
                let src = attrs.backImgSrc;
                if (src) {
                    element.css({
                        "background-image": "url(" + src + ")",
                    });
                }
            }
        },
    };
}

/* @ngInject */
export function spinner() {
    return {
        restrict: "E",
        template: spinnerTemplate,
    };
}

/* @ngInject */
export function textInput() {
    return {
        restrict: "E",
        template: textInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputInputmode: "@",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            inputType: "@",
        },
    };
}

/* @ngInject */
export function otpInput() {
    return {
        restrict: "E",
        template: otpInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
        },
    };
}

/* @ngInject */
export function passwordInput() {
    return {
        restrict: "E",
        template: passwordInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
        },
    };
}

/* @ngInject */
export function textareaInput() {
    return {
        restrict: "E",
        template: textAreaTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
            maxLength: "=",
        },
    };
}

/* @ngInject */
export function radioInput() {
    return {
        restrict: "E",
        template: radioInputTemplate,
        require: "ngModel",
        scope: {
            inputModel: "=ngModel",
            inputDisabled: "=ngDisabled",
            inputName: "@",
            inputLabel: "@",
            inputValue: "=ngValue",
            inputChange: "=ngChange",
            inputClick: "=ngClick",
        },
    };
}

/* @ngInject */
export function cardHeader() {
    return {
        restrict: "E",
        template: cardHeaderTemplate,
        scope: {
            headline: "@",
            prompt: "@",
        },
    };
}

/* @ngInject */
export function actionCard() {
    return {
        restrict: "E",
        transclude: true,
        template: actionCardTemplate,
        scope: {
            headline: "@",
            prompt: "@",
            cardClass: "@",
        },
    };
}

export function fileInput() {
    return {
        require: "ngModel",
        link: function postLink(scope, elem, attrs, ngModel) {
            elem.on("change", function (e) {
                let file = elem[0].files[0];
                ngModel.$setViewValue(file);
            });
        },
    };
}

export function phoneNumberInput() {
    return {
        scope: {
            inputDisabled: "=ngDisabled",
            inputRequired: "=ngRequired",
            inputName: "@",
            inputLabel: "@",
            inputErrors: "=",
        },
        require: "ngModel",
        template: phoneNumberInputTemplate,
        link: function (scope, elem, attrs, ngModelCtrl) {
            scope.updateModel = function () {
                let country = scope.countryCode;
                let nationalPhoneNumber = scope.phoneNumber;
                let finalPhoneNumber;
                if (nationalPhoneNumber.length === 0) {
                    finalPhoneNumber = null;
                } else {
                    finalPhoneNumber = country + nationalPhoneNumber;
                }
                console.log("final value: " + finalPhoneNumber);
                ngModelCtrl.$setViewValue(finalPhoneNumber);
            };

            ngModelCtrl.$render = function () {
                let phoneNumber = phone(ngModelCtrl.$viewValue);
                if (phoneNumber.isValid) {
                    scope.countryCode = phoneNumber.countryCode;
                    scope.phoneNumber = phoneNumber.phoneNumber.substring(
                        phoneNumber.countryCode.length,
                    );
                } else {
                    let countryCode;
                    if (_.isNull(phoneNumber.countryCode)) {
                        countryCode = "+1";
                    } else {
                        countryCode = "";
                    }
                    scope.countryCode = countryCode;
                    scope.phoneNumber = "";
                }
            };
        },
    };
}

export function formTripleAction() {
    return {
        restrict: "E",
        transclude: false,
        template: formTripleActionTemplate,
        scope: {
            nonFieldErrors: "=",
            submitLabel: "@",
            cancelLabel: "@",
            hideCancel: "=",
            deleteLabel: "@",
            deleteFunc: "&",
            showSpinner: "=",
        },
    };
}

export function uiLink() {
    return {
        restrict: "E",
        transclude: false,
        template: uiLinkTemplate,
        scope: {
            state: "@",
            params: "<",
            label: "@",
            target: "@",
            content: "@",
        },
    };
}

/* @ngInject */
export function anchorButton($state) {
    return {
        restrict: "E",
        transclude: false,
        template: anchorButtonTemplate,
        scope: {
            icon: "@",
            iconColor: "@",
            textColor: "@",
            color: "<",
            label: "@",
            state: "@",
            stateObj: "<",
            params: "<",
            direction: "@",
            classes: "@",
        },
        link: function (scope, element, attrs) {
            element.on("click", function () {
                scope.showSpinner = true;
                $state
                    .go(scope.state || scope.stateObj, scope.params)
                    .finally(function () {
                        scope.showSpinner = false;
                    });
            });

            // Clean up the event listener when the scope is destroyed.
            scope.$on("$destroy", function () {
                element.off("click");
            });
        },
    };
}

/* @ngInject */
export function cfVideo() {
    return {
        restrict: "AE",
        scope: {
            pauseCallback: "&",
            playingCallback: "&",
            endedCallback: "&",
            srcId: "@srcId",
            startTime: "<",
        },
        link: function (scope, elem, attr) {
            let stream = elem.find("video-js");
            let CF_STREAM_DOMAIN =
                "customer-jxr2nkggvva4329o.cloudflarestream.com";

            const player = videojs(stream[0], {
                disablePictureInPicture: true,
                fluid: true,
                sources: [
                    {
                        src:
                            "https://" +
                            CF_STREAM_DOMAIN +
                            "/" +
                            scope.srcId +
                            "/manifest/video.m3u8",
                        type: "application/x-mpegURL",
                    },
                ],
                controlBar: {
                    pictureInPictureToggle: false,
                },
            });
            player.poster(
                "https://" +
                    CF_STREAM_DOMAIN +
                    "/" +
                    scope.srcId +
                    "/thumbnails/thumbnail.jpg?time=10s&height=" +
                    Math.round(player.currentHeight()),
            );

            let callbacks = {
                pause: scope.pauseCallback,
                ended: scope.endedCallback,
                playing: scope.playingCallback,
            };
            _.forEach(callbacks, function (func, event) {
                if (!_.isNil(func)) {
                    player.on(event, function (ev) {
                        func({ event: ev });
                    });
                }
            });
            if (scope.startTime) {
                player.currentTime(scope.startTime);
            }
        },
        template: cfVideoTemplate,
    };
}

/* @ngInject */
export function lessonAudio() {
    return {
        restrict: "AE",
        scope: {
            playingCallback: "=",
            pauseCallback: "=",
            endedCallback: "=",
            src: "=src",
        },
        link: function (scope, elem, attr) {
            let audio = elem.find("audio");
            let callbacks = {
                play: scope.playingCallback,
                pause: scope.pauseCallback,
                ended: scope.endedCallback,
            };
            _.forEach(callbacks, function (func, event) {
                if (!_.isNil(func)) {
                    audio.on(event, func);
                }
            });
        },
        template:
            '<audio class="w-full" controls><source ng-src="{{ src }}"></audio>',
    };
}

/* @ngInject */
export function stateTitle($transitions, $translate) {
    return {
        link: function (scope, element, attrs) {
            let listener = function (event, toState) {
                let resolvedVariables = {};
                _.forEach(_.keys(toState.resolve), function (name) {
                    resolvedVariables[name] = event.injector().get(name);
                });
                let pattern = "%s | Harvust";
                let title = _.get(
                    toState.data,
                    "title",
                    "routes.UNTITLED_PAGE",
                );
                title = $translate.instant(title, resolvedVariables);
                title = pattern.replace(/\%s/g, title);
                /* Set the title */
                element.text(title);
            };

            $transitions.onSuccess({}, function (trans) {
                listener(trans, trans.to());
            });
        },

        restrict: "A",

        scope: false,
    };
}

/* @ngInject */
export function stateMetaDescription($transitions, $translate) {
    return {
        link: function (scope, element, attrs) {
            let listener = function (event, toState) {
                let resolvedVariables = {};
                _.forEach(_.keys(toState.resolve), function (name) {
                    resolvedVariables[name] = event.injector().get(name);
                });
                let description = _.get(toState.data, "description");
                if (description) {
                    description = $translate.instant(
                        description,
                        resolvedVariables,
                    );
                    /* Set the title */
                    element.attr("content", description);
                }
            };

            $transitions.onSuccess({}, function (trans) {
                listener(trans, trans.to());
            });
        },
        restrict: "A",
        scope: false,
    };
}

/* @ngInject */
export function profilePicture() {
    return {
        restrict: "E",
        transclude: false,
        template: profilePictureTemplate,
        scope: {
            publicId: "=",
            size: "@",
            imgClass: "@",
        },
    };
}

/* @ngInject */
export function renderMarkdown() {
    return {
        restrict: "E",
        transclude: false,
        scope: {
            content: "=",
        },
        link: function (scope, elem, attr) {
            if (scope.content) {
                let renderedContent = marked(scope.content);
                elem.html(renderedContent);
            }
        },
    };
}

const icons = {
    library_books,
    call_made,
    cancel,
    schedule,
    warning,
    arrow_forward,
    update,
    check_circle,
    timelapse,
    thumb_up,
    close,
    done,
    home,
    help,
    settings,
    menu,
    arrow_drop_down,
    add,
    file_download,
    fiber_manual_record,
    place,
    highlight_off,
    print,
    autorenew,
    clear_all,
    attach_file,
    add_location,
    play_circle_outline,
    description,
    person,
    volume_up,
    arrow_back,
    delete: delete_icon,
    import_export,
    expand_more,
    expand_less,
    remove_circle_outline,
    class: class_icon,
    send,
    phone_enabled,
    phone_disabled,
    arrow_right_alt,
    link,
    open_in_browser,
    open_in_new,
    check,
    sort,
    filter_list,
    cancel_presentation,
    assignment,
    notifications_active,
    person_add,
    create,
    pan_tool,
    done_all,
    timer,
    call,
    directions,
    email,
    directions_car,
    health_and_safety,
    navigate_next,
    badge,
    upload,
    swap_horiz,
    bolt,
    preview,
    send_to_mobile,
    star,
    currency_exchange,
    archive,
    chat,
    mic,
    stop,
    report,
};

/* @ngInject */
export function materialIcon() {
    return {
        restrict: "E",
        transclude: false,
        scope: {
            name: "@",
            classes: "@",
            width: "<",
            height: "<",
        },
        link: function (scope, elem, attr) {
            scope.src = icons[scope.name];
            let img = elem.find("img")[0];
            if (scope.width) {
                img.width = scope.width;
            } else {
                img.width = 24;
            }
            if (scope.height) {
                img.height = scope.height;
            } else {
                img.height = 24;
            }
        },
        template: '<img class="harvust-icon {{classes}}" ng-src="{{src}}">',
    };
}

/* @ngInject */
export function languageAttribute($rootScope) {
    return {
        link: function (scope, element) {
            $rootScope.$on(
                "$translateChangeSuccess",
                function (event, translationResp) {
                    element.attr("lang", translationResp.language || ENGLISH);
                },
            );
        },
    };
}

/* @ngInject */
export function stateProgressBar($state) {
    return {
        restrict: "E",
        scope: {
            states: "<",
        },
        template: stateProgressBarTemplate,
        link: function (scope, element, attrs) {
            scope.isAfter = function (state) {
                let currentStateIndex = scope.states.findIndex($state.includes);
                let thisStateIndex = scope.states.findIndex(function (element) {
                    return element === state;
                });
                return thisStateIndex > currentStateIndex;
            };
        },
    };
}
