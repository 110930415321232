import logoUrl from "../assets/harvust-logo-008.svg";
import frameTemplate from "./frame.html";
import navDropdownTemplate from "./nav_dropdown.html";
import genericNavDropdownTemplate from "./generic_nav_dropdown.html";
import mixpanel from "mixpanel-browser";
import _ from "lodash";

class NavDropDownController {
    /* @ngInject */
    constructor($document, $element, $timeout, AuthService, BeamsService) {
        this.AuthService = AuthService;
        this.BeamsService = BeamsService;
        this.$document = $document;
        this.$element = $element;
        this.$timeout = $timeout;
        this.expanded = false;
        let ctrl = this;
        ctrl.$document.on("click", function (event) {
            if (ctrl.expanded) {
                ctrl.$timeout(function () {
                    let contents = ctrl.$element.find("*");
                    ctrl.expanded = Array.from(contents).includes(event.target);
                });
            }
        });
    }

    logout() {
        let ctrl = this;
        mixpanel.track("Clicked sign out");
        ctrl.AuthService.logout();
        ctrl.BeamsService.stop();
    }
}
export const NavDropdownComponent = {
    controller: NavDropDownController,
    bindings: { account: "<" },
    template: navDropdownTemplate,
};

class GenericNavDropDownController {
    /* @ngInject */
    constructor($document, $element, $timeout, $state, $transitions) {
        this.$document = $document;
        this.$element = $element;
        this.$timeout = $timeout;
        this.$state = $state;
        this.expanded = false;
        let ctrl = this;
        ctrl.$document.on("click", function (event) {
            if (ctrl.expanded) {
                ctrl.$timeout(function () {
                    let contents = ctrl.$element.find("*");
                    ctrl.expanded = Array.from(contents).includes(event.target);
                });
            }
        });

        $transitions.onFinish({}, function (transition) {
            ctrl.currentState = transition.to();
            ctrl.showSpinner = false;
        });
    }

    $onInit() {
        let ctrl = this;
        ctrl.currentState = ctrl.$state.current;
    }

    getNavLabel(state) {
        let ctrl = this;
        for (let navOption of ctrl.navOptions) {
            if (ctrl.$state.includes(navOption.state)) {
                return navOption.label;
            }
        }
    }

    navigate(state) {
        let ctrl = this;
        ctrl.expanded = false;
        ctrl.showSpinner = true;
    }
}
export const GenericNavDropdownComponent = {
    controller: GenericNavDropDownController,
    bindings: { navOptions: "<" },
    template: genericNavDropdownTemplate,
};

class GlobalController {
    /* @ngInject */
    constructor(AuthService, BeamsService, $rootScope) {
        this.LOGO_URL = logoUrl;
        this.AuthService = AuthService;
        this.BeamsService = BeamsService;
        this.$rootScope = $rootScope;
    }

    logout() {
        let ctrl = this;
        mixpanel.track("Clicked sign out");
        ctrl.AuthService.logout();
        ctrl.BeamsService.stop();
    }
}

export const FrameComponent = {
    controller: GlobalController,
    bindings: { account: "<" },
    template: frameTemplate,
};
