"use strict";
import _ from "lodash";
import moment from "moment";

export const DATE_ADDED_END_FILTER = {
    func: function (element, test) {
        return moment(element.growerMembership.created).isSameOrBefore(test);
    },
};
export const DATE_ADDED_START_FILTER = {
    func: function (element, test) {
        return moment(element.growerMembership.created).isSameOrAfter(test);
    },
};
export const WORKER_STATUS_FILTER = {
    func: function (element, test) {
        let activeValue = _.get(element.growerMembership, "active", false);
        if (test === "active") {
            return activeValue;
        } else if (test === "inactive") {
            return !activeValue;
        } else if (test === "all") {
            return true;
        }
    },
};
export const DOCUMENT_STATUS_FILTER = {
    func: function (element, test) {
        if (test === "incomplete") {
            return !_.isEmpty(element.incompletePaperworkRequests);
        } else if (test === "complete") {
            return _.isEmpty(element.incompletePaperworkRequests);
        }
    },
};

export function filtered(collection, filters, filterConfig) {
    collection = _.filter(collection, function (member) {
        let x = [];
        _.forEach(filters, function (value, key) {
            let filter = filterConfig[key];
            if (filter && value) {
                x.push(filter.func(member, value));
            }
        });
        return _.every(x);
    });
    return collection;
}

export const MOBILE_STATUS_FILTER = {
    func: function (element, test) {
        if (test === "none") {
            return _.isNil(element.user.mobile);
        } else {
            return element.user.mobile_status === test;
        }
    },
};

export const CREW_FILTER = {
    func: function (element, test) {
        if (_.isEmpty(test)) {
            return true;
        }

        return !_.isEmpty(
            _.intersection(_.map(element.memberships, "group"), test),
        );
    },
};
